<template>
  <div class="company-select-list d-flex flex-column justify-content-center">
    <div v-for="company in sortedCompanies" :key="company.id">
      <div
        class="company-container"
        :class="updateAvailable(company) ? 'update-available' : ''"
        @click="$emit('company-selected', company)"
      >
        <feather-icon
          v-if="updateAvailable(company)"
          class="alert-circle update-available"
          type="alert-circle"
        />
        <div>
          {{ company.name }}
        </div>
        <div class="arrow-container">
          <fa-icon icon="chevron-right" class="fa-md arrow" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sortArrayByKey } from '@/helpers'

export default {
  name: 'CompanySelectList',
  components: {
    FeatherIcon: () => import('@/components/shared/FeatherIcon'),
  },
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    sortedCompanies() {
      return sortArrayByKey(this.companies)
    },
  },
  methods: {
    updateAvailable(company) {
      return company.stageline?.route?.show_alert
    },
  },
}
</script>
<style lang="scss" scoped>
.company-select-list {

  .company-container {
    border: 1px solid #B7B7B7;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0.125em;
    padding: 1em;

    .arrow-container {
      margin-left: auto;
    }
  }
  .company-container:hover {
    cursor: pointer;
    box-shadow: $ct-ui-box-shadow-3;
  }
  .update-available {
    display: flex;
    font-weight: bold;

    .alert-circle {
      display: flex;
      margin-right: 0.5em;
    }
  }
}
</style>
